import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Make/Do`}</h1>
    <h4>{`June 18–24, 2011 | Vinalhaven, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "133.33333333333331%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAbABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHZDRiJo+UjSk0ooGZX/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIDEQQQEiL/2gAIAQEAAQUCnpIQ9j0TM3bRKtSY/TE2Ytpr/8QAFREBAQAAAAAAAAAAAAAAAAAAASD/2gAIAQMBAT8BY//EABURAQEAAAAAAAAAAAAAAAAAABAB/9oACAECAQE/ASH/xAAbEAABBAMAAAAAAAAAAAAAAAABEBEhMQASIP/aAAgBAQAGPwLUJDJQxxCVz//EAB4QAAICAgIDAAAAAAAAAAAAAAABESEQMUFxUWGx/9oACAEBAAE/IXV2Nc8Chcp1ey88/CPV3Q6sSNKsLCqRtEitrs3WP//aAAwDAQACAAMAAAAQbBvN/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQAQIf/aAAgBAwEBPxAcic//xAAWEQEBAQAAAAAAAAAAAAAAAAABESD/2gAIAQIBAT8QGOH/xAAeEAEAAwACAgMAAAAAAAAAAAABABExIWHB4UGh8P/aAAgBAQABPxA69zpB7hls4IR7loVsS1i4H4GEbYFe+c4YkAlCWDplP7uXip0pz6lepsdvtiUFUB8Qwn//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Make/Do",
          "title": "Make/Do",
          "src": "/static/3c390e77e3b32491c9bcdafa3f9a3aae/e5166/IMG_3440.jpg",
          "srcSet": ["/static/3c390e77e3b32491c9bcdafa3f9a3aae/f93b5/IMG_3440.jpg 300w", "/static/3c390e77e3b32491c9bcdafa3f9a3aae/b4294/IMG_3440.jpg 600w", "/static/3c390e77e3b32491c9bcdafa3f9a3aae/e5166/IMG_3440.jpg 1200w", "/static/3c390e77e3b32491c9bcdafa3f9a3aae/d9c39/IMG_3440.jpg 1800w", "/static/3c390e77e3b32491c9bcdafa3f9a3aae/df51d/IMG_3440.jpg 2400w", "/static/3c390e77e3b32491c9bcdafa3f9a3aae/4d663/IMG_3440.jpg 3312w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="http://designobserver.com/feature/make-do/27998" title="Design Observer 'Make/Do' Article" mdxType="Outcomeitem" />
    <Outcomeitem link="https://www.eyemagazine.com/blog/post/on-the-make" title="Eye Magazine: On the Make" mdxType="Outcomeitem" />
    <Outcomeitem link="https://epi.aiga.org/embrace-the-strain" title="AIGA: Embrace the Strain" mdxType="Outcomeitem" />
    </Sidebar>
    <p><strong parentName="p">{`To “make do” is to create something out of immediately available resources, often within a time constraint. The phrase seems to perfectly describe design practice in the 21st Century, when we are not only more conscious of the resources we use, but all too aware that compromise is part of design: we are compromised by time, by budgets and by all of the parties invested in a project. Project research becomes an ongoing dialog with content, material and context–questioning what is possible and what is needed. Designers synthesize, compromise and improvise1.`}</strong></p>
    <p><strong parentName="p">{`Look a little closer and “make do” unfolds to reveal another interesting side of design practice. Between making and doing, it hints, there might be a difference; between the having the idea to make something and its execution. Often the material or contextual constraints bend the concept and the piece gains a life of its own. Or someone else makes your design do something it wasn’t supposed to.`}</strong></p>
    <p><strong parentName="p">{`When you think about it, there are very few designs that are used exactly as they were intended2. Books are read and interpreted in different ways, but also used to decorate rooms and prop up tables. Software only becomes robust once people have used it and misused it. Products and buildings age, crack, lose their luster, get repaired, remodeled and gain character over time. Buildings are never really finished, even when the architect takes the photos, the curtains cover the windows and the laundry is drying on the porch. Cities, no matter how carefully planned, are really created by people, and how they use them and improvise in them, as part of their daily lives.`}</strong></p>
    <p><strong parentName="p">{`DesignInquiry 2011: MAKE/DO, investigated the idea that all design in some sense requires improvisation, and that no design is really the work of a single author, even if it seems that way. It celebrated designs that produce something out of nothing, and design as setting the rules of a game in which time-budget-material-context-user are partners and playmates. We asked whether we can design in such a way that encourages people to make do–to make our thing do something new.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      